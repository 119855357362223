
import commaNumber from 'comma-number'
import analytics from '@/helpers/analytics'

export default {
  components: {
    DropdownCompareItem: () => import('@/components/DropdownCompareItem'),
    DropdownPartnerItem: () => import('@/components/DropdownPartnerItem')
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      expandList: false,
      maxItemsPreview: 3
    }
  },
  computed: {
    hasItems () {
      return this.items.length > 0
    },
    previewItems () {
      return this.items.slice(0, this.maxItemsPreview)
    },
    localCurrency () {
      return this.$store.state.localCurrency
    },
    showCompareAll () {
      return this.items.some(item => this.isCompareItem(item))
    }
  },
  methods: {
    isCompareItem(item) {
      return item.component === 'DropdownCompareItem'
    },
    toggleExpandList() {
      if (!this.expandList) {
        const eventName = 'dropdown-expand'
        analytics.track(eventName, this)
        console.info('Expand list')
      }
      this.expandList = !this.expandList
    },
    minPriceFormatted(price) {
      return commaNumber(price)
    },
    partnerClick(item) {
      this.$emit('partnerClick', item)
    },
    openDeeplinkUrl(index) {
      const isCompareItem = this.isCompareItem(this.items[index]);
      const event = isCompareItem ? 'preview-dropdown-compare-click' : 'preview-dropdown-pricelink-click'
      const itemType = isCompareItem ? 'compare' : 'partner'
      const deeplinkUrl = isCompareItem ? this.items[index].deepLink : this.items[index].partnerDeeplinkUrl

      if (!isCompareItem) {
        this.$emit('partnerClick', this.items[index])
      }

      analytics.track(event, this)
      console.info(`Open ${itemType} deeplink URL`)
      window.newTab = window.open(deeplinkUrl, '_blank')
    },
    compareAll() {
      const eventName = 'dropdown-compare-all-click-flight'
      analytics.track(eventName, this)
      this.$emit('compareAll')
    },
    displayPrice (item) {
      return this.localCurrency ? item.minPriceLocal || item.priceLocal : item.minPrice || item.price
    },
    displaySymbol (item) {
      return this.localCurrency ? item.currencySymbolLocal : item.currencySymbol
    }
  }
}
