
import wait from 'waait'
import qs from 'qs'
import isNull from 'lodash/isNull'
import cloneDeep from 'lodash/cloneDeep'
import transformParams from '@/helpers/transformParams'
import apiDebugger from '@/helpers/apiDebugger'
import getKayakAdsParams from '@/helpers/getKayakAdsParams'
import getMediaAlphaCompareParams from '@/helpers/getMediaAlphaCompareParams'
import analytics from '@/helpers/analytics'
import { flights as pairings } from '@/static/data/pairings.json'
import { differenceInDays } from 'date-fns'
import insertSojernTag from '@/helpers/insertSojernTag'
import loadScript from '@/helpers/loadScript'
import criteo from '@/helpers/criteo'
import GoogleAdSense from '@/components/GoogleAdSense.vue';

function extractCityName (text) {
  const regex = /-\s*([\w\s]+),/;
  const matches = text.match(regex);

  if (matches && matches.length >= 2) {
    return matches[1].trim();
  }

  return null;
}

export default {
  data () {
    return {
      apiData: null,
      uuid: null,
      nextPollUrl: null,
      nextPollSleep: 1000,
      pollCount: 0,
      pollLimit: 20,
      cooldown: null,
      isLoading: true,
      inlineAds: null,
      mediaAlphaParams: null,
      compareItems: [],
      compareModalFlight: null,
      compareToSearchId: null,
      compareAllUrl: null,
      showMediaAlphaMobileFooterAd: false,
      hasShareTripsChat: false,
      debugData: null,
      modals: {
        compare: true
      }
    }
  },
  computed: {
    showMAWidget () {
      return this.$store.state.siteConfig.showMAWidget
    },
    adSlots () {
      return this.$config.ADSENSE_ID
    },
    adsenseId() {
      return this.$config.ADSENSE_ID ?? null;
    },
    country () {
      return this.$store.state.country
    },
    isMobile () {
      return this.$store.state.device.isMobile
    }
  },
  watch: {
    '$route.query': {
      handler: 'load',
      deep: true
    }
  },
  mounted () {
    this.load()

    try {
      window.adara('send', {
        pageType: 'Results Page',
        siteLanguage: this.$store.state.language,
        siteCountry: this.$store.state.country
      })
    } catch (e) {
      console.info('Error firing Adara event')
    }
  },
  methods: {
    async load () {
      const { query } = this.$route
      const { country, site, entryId, siteConfig, siteType, isIdentified, ostype } = this.$store.state
      const { deviceCategory } = this.$store.getters

      if (siteConfig.shareTrips) {
        loadScript(`https://chat.sharetrips.com/embed/main.js?site=${siteConfig.shareTrips}`, true)
      }

      this.isLoading = true

      setTimeout(() => {
        this.scrollTo('results')
      }, 500)

      if (!isIdentified) {
        console.info('Wait for visitor identification...')
        return setTimeout(() => {
          this.load()
        }, 500)
      }

      const now = new Date()

      if (isNull(this.cooldown) || (now > this.cooldown)) {
        this.cooldown = new Date()
        this.cooldown.setSeconds(this.cooldown.getSeconds() + 5)
      } else {
        console.info('Waiting for cooldown...')
        return setTimeout(() => {
          this.load()
        }, 1000)
      }

      this.uuid = crypto.randomUUID()

      this.$store.commit('clearSearchFilters')
      this.$store.commit('resetSearchResults')

      if (!query.fromCode || !query.toCode || !query.departureDate || !query.returnDate) {
        return this.$router.push('/')
      }

      const apiData = transformParams.toApi(query, pairings)
      this.apiData = apiData

      // Sojern
      try {
        const sojernParams = {
          p: 'search',
          eml: this.$store.state.user.data.email,
          ccid: this.$store.state.entryId,
          tad: apiData.adults,
          tch: apiData.children,
          t: apiData.adults + apiData.children,
          ppot: 'leisure',
          l: window.navigator.language,
          vid: 'flight',
          fow: !apiData.roundtrip,
          fc: apiData.cabinClass,
          fd: apiData.roundtrip ? differenceInDays(new Date(apiData.inboundDate), new Date(apiData.outboundDate)) : null,
          pname: window.location.href,
          fc2: extractCityName(apiData.originText),
          fn2: '',
          fc1: extractCityName(apiData.destinationText),
          fn1: '',
          fd1: apiData.outboundDate,
          fd2: apiData.inboundDate,
          fa1: apiData.origin,
          fa2: apiData.destination
        }

        insertSojernTag(sojernParams, this)
      } catch (e) {
        console.info('Error when inserting the Sojern script')
      }

      const params = {
        ...apiData,
        debug: this.$store.state.isDebugEnabled ? 1 : 0,
        device: deviceCategory,
        country: country || 'WW',
        site: site.replace('v2.', '').replace('new.', '').replace('ab.', '').replace('dev-ab.', ''),
        entryId,
        adid: query.gclid || query.msclkid,
        sitebrand: siteType
      }

      // Results
      try {
        const { 
          deeplinks, 
          results, 
          search, 
          filters, 
          debug 
        } = await this.$axios.$get(this.$config.API_URL + '/flights/search', { params })
        if (results) {
          this.$store.commit('appendSearchResults', Object.values(results))
          this.insertShareTripsChat(Object.values(results))
        }

        if (search && search.totalCount == 0 && search.finished == true) {
          const debuggerPayload = {
            params: params, 
            search: search,
          }
          apiDebugger.log('no-results', entryId, debuggerPayload, this)

          analytics.track('meta-no-results-response', this)
        }

        if (debug !== undefined && debug.debugUrl !== undefined) {
          this.debugData = debug
        }

        if (deeplinks) {
          this.$store.commit('setDeeplinks', Object.values(deeplinks))
        }

        if (filters && filters.airlines) {
          this.$store.commit('setSearchAirlines', Object.values(filters.airlines))
        }

        if (search && !search.finished) {
          this.nextPollUrl = search.nextPollUrl
          this.nextPollSleep = search.nextPollSleep
          this.pollCount = 0
          this.poll()
        }

        if (search.finished) this.isLoading = false
      } catch (e) {
        return this.$toast.error('An error has ocurred, please reload and try again', { position: 'top-center', duration: 4000 })
      }

      // Kayak
      try {
        const kayakQueryString = qs.stringify(params) + '&' + getKayakAdsParams(params, entryId)

        this.compareItems = []
        this.compareToSearchId = null

        const { inlineItems } = await this.$axios.$get(this.$config.API_URL + `/ads/inline/flights?${kayakQueryString}`)
        this.$store.commit('setInlineAds', inlineItems)

        if (inlineItems !== undefined) {
/*
          // Disabled for CORS error
          inlineItems.forEach(item => {
            try {
              this.$axios.get(item.impressionUrl, null, {
                "Origin": "farescraper.com",
                "User-Agent": ostype
              });
            } catch (ex) {
              console.info('Error triggering impressionUrl')
            }
          })
*/
        }

        // Get compare partners
        const { compareToItems, searchId, compareAllUrl } = await this.$axios.$get(this.$config.API_URL + '/ads/compare/flights', { params })
        this.compareItems.push(...compareToItems)
        this.compareToSearchId = searchId
        this.compareAllUrl = compareAllUrl
        this.$store.commit('setCompareToAdsModal', true)
        this.$store.commit('setCompareItems', this.compareItems)
      } catch (e) {
        console.info('Error inserting Kayak ads')
      }

      // Media Alpha
      try {
        const placements = { 'YwG9lercKqaybDeXOgy1Sdgth8BP-g': 'MEDIA_ALPHA_A' }

        this.mediaAlphaParams = getMediaAlphaCompareParams(entryId, params, Object.keys(placements))
        window.MediaAlphaExchange = cloneDeep(this.mediaAlphaParams)

        setTimeout(() => {
          window.MediaAlphaExchange__load(placements)
          setTimeout(() => {
            this.addMediaAlphaListeners(placements)
          }, 1400)
        }, 200)
      } catch (e) {
        console.info('Error inserting Media Alpha Ads')
      }

      console.log(results)
    },
    async poll () {
      if (this.pollCount >= this.pollLimit) {
        console.info('Poll limit reached')
        this.isLoading = false
        return
      }

      this.pollCount = this.pollCount + 1
      await wait(this.nextPollSleep)

      try {
        const { results, search, filters } = await this.$axios.$get(this.nextPollUrl)

        if (results) {
          this.$store.commit('resetSearchResults')
          this.$store.commit('appendSearchResults', Object.values(results))
          this.insertShareTripsChat(Object.values(results))
        }

        if (filters && filters.airlines) {
          this.$store.commit('setSearchAirlines', Object.values(filters.airlines))
        }

        if (search && !search.finished) {
          this.nextPollUrl = search.nextPollUrl
          this.nextPollSleep = search.nextPollSleep
          this.poll()
        }

        if (search.finished) this.isLoading = false
      } catch (e) {
        console.info('Error polling')
      }
    },
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
    async compareAll (flight) {
      this.pushCriteoParams()

      const compareAllWindow = window.open('/loading', '_blank')

      if (this.isMobile && flight !== undefined && flight !== null && flight.partners.length) {
        compareAllWindow.location.href = flight.partners[0].partnerDeeplinkUrl
        return        
      }

      if (this.compareAllUrl !== null) {
        compareAllWindow.location.href = this.compareAllUrl
        return
      }

      const allProviders = this.compareItems.reduce((providers, item) => {
        providers.push(item.providerCode)
        return providers
      }, [])
      const { entryId } = this.$store.state

      const compareAllQueryString = qs.stringify({
        entryId,
        providerCodes: allProviders.join(','),
        searchId: this.compareToSearchId
      }, { arrayFormat: 'indices' })

      // Results
      try {
        const { url } = await this.$axios.$get(`ads/kayak/compare/flights/iframe?${compareAllQueryString}`)
        compareAllWindow.location.href = url
      } catch (e) {
        console.info('Error getting Kayak iframe')
        return this.$toast.error('An error has ocurred, please reload and try again', { position: 'top-center', duration: 4000 })
      }

      this.modals.compare = false
    },
    addMediaAlphaListeners (placements) {
      Object.keys(placements).forEach((key) => {
        const buttons = document.querySelectorAll('#MEDIA_ALPHA_A button')
        if (buttons?.length > 0) {
          buttons.forEach((button, index) => {
            button.addEventListener('click', () => {
              const customerId = this.$store.state.entryId
              const id = key + placements[key] + button.id
              const price = 0
              const currency = 'USD'

              const options = { customerId, currency, price, id }
              criteo.purchaseTag(this.$store, options)
            })
          })
        }
      })
    },
    pushCriteoParams () {
      if (!this.compareItems.length) return
      const customerId = this.$store.state.entryId
      const price = this.compareItems.reduce((acc, item) => acc + item.bid, 0) / this.compareItems.length
      const id = customerId + this.compareItems.reduce((acc, item) => acc + item.id, '')
      const currency = 'USD'

      const options = { customerId, currency, price, id }
      criteo.purchaseTag(this.$store, options)
    },
    openCompareModal ({flight}) {
      if (!this.compareItems) {
        return
      }

      this.compareModalFlight = (flight !== undefined) ? flight : null

      if (this.$store.state.inlineAdDeeplink) {
        analytics.track('modal-compare-open-click', this)
      } else {
        analytics.track('modal-compare-open-auto', this)
      }
      this.modals.compare = true
    },
    closeCompareModal () {
      this.modals.compare = false
    },
    insertShareTripsChat (results) {
      if (this.hasShareTripsChat || !results.length) return

      const { apiData } = this

      try {
        window.ShareTripsParamData = {
          type: 'flight',
          tripType: apiData.roundtrip ? 'roundtrip' : 'oneway',
          origin: apiData.origin,
          destination: apiData.destination,
          departDate: apiData.outboundDate,
          returnDate: apiData.inboundDate,
          direct: apiData.preferDirects ? 'y' : 'n',
          adults: apiData.adults,
          childs: apiData.children,
          infants: 0,
          classType: apiData.cabinClass[0],
          price: results[0].minPrice,
          currency: 'USD'
        }

        this.hasShareTripsChat = true
      } catch (e) {
        console.info('Error inserting Share Trips')
      }
    }
  }
}
